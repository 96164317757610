import React, { useContext } from "react";
import AppContext from "../../context/AppContext";
import styled from "styled-components";
import Breadcrumb from "../navigation/Breadcrumb";
import CartParts from "../components/parts/CartParts";
import { Helmet } from "react-helmet";
import useInventory from "../../requests/hooks/useInventory";

const Wrapper = styled.div`
  margin-left: 195px;
  margin-top: 25px;
  width: max-content;
  min-width: 800px;
`;
const Icon = styled.img`
  margin-right: 8px;
  margin-bottom: 12px;
  width: 50px;
  height: 45px;
  object-fit: contain;
  float: left;
`;
const Title = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black);
  padding-top: 14px;
`;
const Button = styled.button`
  float: right;
  color: white;
  width: 300px;
  height: 50px;
  margin-top: 0px;
  margin-bottom: 12px;
  margin-left: 12px;
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: var(--reddish-orange);
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
`;
const SecondaryButton = styled.button`
  float: right;
  color: white;
  width: 200px;
  height: 42px;
  margin-top: 4px;
  margin-bottom: 12px;
  margin-left: 12px;
  border-radius: 4px;
  border: solid 1px #ffffff;
  background-color: var(--CP-blue);
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
`;
const TotalLabel = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: var(--black);
`;
const Total = styled.div`
  float: right;
  font-size: 21px;
  font-weight: bold;
  color: var(--black);
`;
const LightRule = styled.hr`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 1px;
  color: var(--very-light-grey);
  background-color: var(--very-light-grey);
  border: none;
`;
const ThickRule = styled.hr`
  margin-top: 24px;
  margin-bottom: 16px;
  width: 100%;
  height: 2px;
  color: var(--greyish-brown);
  background-color: var(--greyish-brown);
  border: none;
`;
const DarkRule = styled.hr`
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 1px;
  color: var(--greyish-brown);
  background-color: var(--greyish-brown);
  border: none;
`;

export default function ShoppingCart(props) {
  const context = useContext(AppContext);
  const { cartParts, goto, removeFromCart, updateQuantity, token, claims } = context;
  const shownPartIds = cartParts.map(part => part.partId);
  const inventory = useInventory(shownPartIds, token);
  const hideTotal = cartParts.some(part => !part.price);
  function calcTotalPrice(parts) {
    const sum = (total, part) => total + part.price * part.quantity;
    const format = price => `$${Number.parseFloat(price).toFixed(2)}`;
    const total = parts.reduce(sum, 0);
    return format(total);
  }

  const Proceed = () => {
    if (!cartParts || cartParts.length === 0) {
      return "";
    }

    if (claims.UserType === "Guest") {
      return <>
      <Button onClick={() => goto("/Checkout")}>Checkout as Guest</Button>
      <SecondaryButton onClick={() => goto(`/Login?returnUrl=${props.location.pathname}`)}>Sign in</SecondaryButton>
    </>
    }

    return <Button onClick={() => goto("/Checkout")}>Checkout</Button>}

  return (
    <Wrapper>
      <Helmet>
        <title>Shopping Cart - Case Parts Commercial Refrigeration Parts Specialists</title>
        <meta
          name="description"
          content="Case Parts Commercial Refrigeration Parts Specialists including Latches, Hinges, Gaskets, Heaters, Pans, Walk-In Equipment, Electrical, Thermometers, Alarms, Shelving"
        />
      </Helmet>
      <Icon src="/images/cart.svg" />
      <Title>
        Shopping Cart
        <Breadcrumb title="Continue shopping" />
      </Title>
      <LightRule />
      <CartParts
        parts={cartParts}
        onUpdateQuantity={updateQuantity}
        history={props.history}
        onRemoveFromCart={removeFromCart}
        inventory={inventory}
      />
      <ThickRule />
      {hideTotal && "Price will be provided in email"}
      {!hideTotal && (
        <>
          <Total>{calcTotalPrice(cartParts)}</Total>
          <TotalLabel>TOTAL</TotalLabel>
        </>
      )}
      <DarkRule />
      <Proceed />
    </Wrapper>
  );
}
